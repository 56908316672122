
import {Component, Vue} from 'vue-property-decorator';
import router from "@/router";

@Component({
  components: {},
})
export default class NotAuthorised extends Vue {
  public goHome(): void {
    router.push({path: '/'})
  }
}
